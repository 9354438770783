export function filterPrice() {
    function destroyExistingSlider(){
        if(rangeSlider && rangeSlider.noUiSlider){
            rangeSlider.noUiSlider.destroy();
        }
    }
    destroyExistingSlider();
    let filterContainer = '#filter ';
    if (get_vw() > screen_lg) filterContainer = '#desktop-filters-list ';

    let rangeSliderWidget = document.querySelectorAll(filterContainer + '.filter_price .range-price-slider');

    if (!rangeSliderWidget.length) return;

    for (let i = 0; i < rangeSliderWidget.length; i++) {
        rangeSlider = rangeSliderWidget[i].querySelector('.range-slider-ui');
        let valueMinInput = rangeSliderWidget[i].querySelector('.range-slider-value-min'),
            valueMaxInput = rangeSliderWidget[i].querySelector('.range-slider-value-max');

        let options = {
            dataStartMin: parseInt(rangeSliderWidget[i].dataset.startMin, 10),
            dataStartMax: parseInt(rangeSliderWidget[i].dataset.startMax, 10),
            dataMin: parseInt(rangeSliderWidget[i].dataset.min, 10),
            dataMax: parseInt(rangeSliderWidget[i].dataset.max, 10),
            dataStep: parseInt(rangeSliderWidget[i].dataset.step, 10)
        }

        let dataCurrency = rangeSliderWidget[i].dataset.currency;

        noUiSlider.create(rangeSlider, {
            start: [options.dataStartMin, options.dataStartMax],
            connect: true,
            step: options.dataStep,
            pips: {mode: 'count', values: 5},
            tooltips: true,
            range: {
                'min': options.dataMin,
                'max': options.dataMax
            },
            format: {
                to: function (value) {
                    return `${parseInt(value, 10)} ${dataCurrency ? dataCurrency : '$'}`;
                },
                from: function (value) {
                    return Number(value);
                }
            }
        });

        rangeSlider.noUiSlider.on('update', (values, handle) => {
            let value = values[handle];
            value = value.replace(/\D/g, '');
            if (handle) {
                valueMaxInput.value = Math.round(value);
                // valueMaxInput.fireEvent("change");
                // productFilter.set('price1', valueMaxInput.value);
            } else {
                valueMinInput.value = Math.round(value);
                // valueMinInput.fireEvent("change");
                // productFilter.set('price0', valueMinInput.value);
            }
        });

        rangeSlider.noUiSlider.on('end', (values, handle) => {
            let value = values[handle];
            value = value.replace(/\D/g, '');
            if (handle) {
                // valueMaxInput.value = Math.round(value);
                // valueMaxInput.fireEvent("change");
                productFilter.set('price1', valueMaxInput.value, get_vw() > screen_lg);
            } else {
                // valueMinInput.value = Math.round(value);
                // valueMinInput.fireEvent("change");
                productFilter.set('price0', valueMinInput.value, get_vw() > screen_lg);
            }
        });
        valueMinInput.addEventListener('change', function () {
            rangeSlider.noUiSlider.set([this.value, null]);
            productFilter.set('price0', this.value, get_vw() > screen_lg);
        });

        valueMaxInput.addEventListener('change', function () {
            rangeSlider.noUiSlider.set([null, this.value]);
            productFilter.set('price1', this.value, get_vw() > screen_lg);
        });
    }
}